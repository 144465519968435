import React from "react";
import googleRating from "../../../../static/images/google-rating.png";


const VideoSection = (props) => {
    const [showDetails2, setShowDetails2] = React.useState(false);
    const { title, paragraph1, paragraph2, paragraph3} = props;
    return (
        <section className="internet-section3">
            <div className="container mx-auto px-4">
                <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 sm:gap-6 md:gap-8">
                    <div className="internet-section3__left">
                        <div className="">{/*aspect-w-4 aspect-h-7*/}
                                {/* // title="Purple Cow Internet - Internet Provider Nova Scotia, Prince Edward Island, Newfoundland" */}
                            <iframe
                                width="100%"
                                height="580"
                                src="https://www.youtube.com/embed/Nf6w1TFQtLA"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="internet-section3__right">
                        <h3 className="h3">{title}</h3>
                        <p className="p2">{paragraph1}</p>
                        <p className="p2 large-view-show">{paragraph2}</p>
                        <p className="p2 large-view-show">{paragraph3}</p>
                        {showDetails2 && (
                            <>
                            <p className="p2">{paragraph2}</p>
                            <p className="p2">{paragraph3}</p>
                            </>
                        )}
                        <p className={showDetails2 ? 'p2 learn-more-mobile show-detail' : 'p2 learn-more-mobile '} onClick={() => setShowDetails2(!showDetails2)} >
                            {showDetails2 ? 'Hide Details' : 'Learn More'}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="#fbbd69"
                                strokeWidth={2}
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                            </svg>
                        </p>
                        <img src={googleRating} alt="google rating" className="google-rating" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VideoSection